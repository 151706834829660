<script>
import { Icon } from '@maxsystems/ui/elements'

import search from '../icons/search.svg'
import StartOver from './StartOver'
import brandable from '~/mixins/brandable'
import { BtnFavorites, ShareButton } from '~/plugins/board/components'
import share from '~/plugins/board/icons/share.svg'

export default {
  name: 'PrimaryNav',
  components: {
    BtnFavorites,
    Icon,
    ShareButton,
    StartOver
  },
  mixins: [
    brandable
  ],
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    /** Whether user is navigating from showroom.max.auto */
    showroomNavigation: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    icons: { search, share },
    observer: null
  }),
  computed: {
    hasBoardModule: vm => vm.$store.getters['opportunity/modules'].has('board'),
    instanceName: vm => vm.$store.state.instance.name,
    inventoryId: vm => vm.$route.params?.inventory,
    isInternalPage: vm => vm.$route.path !== '/'
  },
  mounted () {
    // Expands the header spacing if the navbar get to multi-rows due to
    // a small screen to prevent overlapping layout components
    this.observer = new ResizeObserver(([entry]) => {
      // setTimeout prevents error: ResizeObserver loop limit exceeded
      setTimeout(() => {
        const { height } = entry.target.getBoundingClientRect()
        this.$refs.placeholder.style.height = `${height}px`
      }, 0)
    })

    this.observer.observe(this.$refs.header)
  },
  beforeDestroy () {
    this.observer?.disconnect()
  }
}
</script>

<template>
  <div
    ref="placeholder"
    class="navigation--primary--bdc"
  >
    <header
      ref="header"
      class="surface surface--elevation-0"
    >
      <router-link
        v-if="selectedLogo"
        to="/"
      >
        <img
          :alt="instanceName"
          class="navigation--primary--bdc__logo"
          :src="selectedLogo"
        >
      </router-link>
      <StartOver
        v-if="showroomNavigation"
        class="ml-12 hidden-sm-and-down"
      />

      <div
        v-if="isInternalPage"
        class="navigation--primary--bdc__actions d-print-none"
      >
        <VBtn
          :ripple="false"
          color="primary"
          text
          icon
          to="/"
        >
          <Icon
            :src="icons.search"
            width="32"
          />
        </VBtn>
        <BtnFavorites
          v-if="hasBoardModule"
          size="32"
        />
        <ShareButton
          v-if="hasBoardModule"
          v-slot="{ share }"
          :inventory-id="inventoryId"
        >
          <VBtn
            :ripple="false"
            color="primary"
            text
            icon
            @click="share"
          >
            <Icon
              :src="icons.share"
              width="32"
            />
          </VBtn>
        </ShareButton>
      </div>
    </header>
  </div>
</template>

<style lang="scss">
.navigation--primary--bdc {
  margin-bottom: -1px;

  .surface {
    align-items: center;
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
    line-height: 0;
    min-height: 56px;
    max-width: 1400px;
    padding: 4px 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;

    @include breakpoint('md-and-up') {
      height: 72px;
      padding: 8px 24px;

      @supports (backdrop-filter: saturate(180%) blur(5px)) {
        backdrop-filter: saturate(180%) blur(5px);
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &__actions {
    display: flex;
    margin-left: auto;
  }

  &::after {
    content: " ";
    display: block;
    height: 71px;
  }

  &__logo {
    max-height: 48px;
    max-width: 100%;

    @include breakpoint('md-and-up') {
      max-height: 56px;
    }
  }

  .v-btn {
    text-transform: none;
  }

  .v-btn--icon {
    &.v-btn--active::before,
    &.v-btn:hover::before,
    &.v-btn:focus::before {
      background-color: transparent;
    }
  }

  .btn__favorites {
    margin: 0 8px;

    @include breakpoint('md-and-up') {
      margin: 0 24px;
    }
  }
}

@media print {
  .navigation--primary--bdc .surface {
    position: static;
    max-width: unset;
  }
}
</style>
